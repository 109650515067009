import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';
import {formatShortId} from 'shared/utils.js';

import ReferenceField from './ReferenceField.js';

export default function DepositReferenceField({id, ...props}) {
  const history = useHistory();
  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listDeposits"
      accessor={(row) => formatShortId(row.short_id)}
      action={(deposit) =>
        history.push(`${BACKOFFICE_PATHS.deposits}/${deposit.id}`)
      }
    />
  );
}
