import React from 'react';

import Table from '../Table.js';
import {LabelWrapper} from '../label_wrapper/LabelWrapper.js';

export function DataGridField({label, value, columns}) {
  return (
    <LabelWrapper label={label}>
      <Table data={value} columns={columns} />
    </LabelWrapper>
  );
}
