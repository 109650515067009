import {initializeEffects} from 'shared/effects';

import store from './store/store.js';

export const {alert, confirm, handleError, reportError} =
  initializeEffects(store);

export async function confirmClose({isDirty}) {
  if (!isDirty) return true;

  return !(await confirm({
    title: 'Do you want to Abort?',
    text: 'If you cancel, the entry will be deleted. You can create the entry again at any later time.',
    abort_label: 'Abort',
    proceed_label: 'Return to the entry',
  }));
}
