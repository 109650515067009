import {useSelector} from 'react-redux';
import {formatPrice, formatDate} from 'shared/utils.js';
import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import DepositReferenceField from '../../components/reference_field/DepositReferenceField.js';
import DepositGuaranteeApplicationsReferenceField from '../../components/reference_field/DepositGuaranteeApplicationReferenceField.js';
import SearchInput from '../../components/search_input/SearchInput.js';

import ClaimDialog from './ClaimDialog.js';
import styles from './ClaimsScreen.module.scss';

export default function ClaimsScreen() {
  const {is_admin} = useSelector((state) => state.user.account);
  const {refresh} = useSelector((state) => state.claims);

  const {
    orderBy,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
    setSearchQuery,
  } = useFetchTableData({method: 'listClaims', refresh});

  const history = useHistory();

  if (!is_admin) {
    return null;
  }

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.claims}/:id`}>
        <ClaimDialog onExited={() => history.push(BACKOFFICE_PATHS.claims)} />
      </Route>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(claim) =>
            history.push(`${BACKOFFICE_PATHS.claims}/${claim.id}`)
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'deposit_id',
    Header: 'Deposit',
    accessor: 'deposit_id',
    Cell: ({value}) => <DepositReferenceField id={value} />,
    text_styles: 'body2',
  },
  {
    id: 'manager',
    Header: 'Manager',
    accessor: 'manager_id',
    Cell: ({value}) => <ManagerReferenceField id={value} />,
    text_styles: 'body2',
  },
  {
    id: 'deposit_guarantee_application_id',
    Header: 'Deposit guarantee application',
    accessor: 'deposit_guarantee_application_id',
    Cell: ({value}) => (
      <DepositGuaranteeApplicationsReferenceField id={value} />
    ),
    text_styles: 'body2',
  },
  {
    id: 'amount_cents',
    Header: 'Amount',
    accessor: ({amount_cents}) => formatPrice(amount_cents),
    text_styles: 'body2',
  },
  {
    id: 'closed_at',
    Header: 'Closed',
    accessor: 'closed_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
