import {useSelector} from 'react-redux';
import {formatDate} from 'shared/utils.js';
import {useHistory, Switch, Route} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';

import styles from './TenantPromotionCampaignsScreen.module.scss';
import CreateTenantPromotionCampaignDialog from './CreateTenantPromotionCampaignDialog.js';
import TenantPromotionCampaignDialog from './TenantPromotionCampaignDialog.js';

export default function TenantPromotionCampaignsScreen() {
  const {refresh} = useSelector((state) => state.tenant_promotion_campaigns);

  const {
    orderBy,
    resetTable,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listTenantPromotionCampaigns', refresh});

  const history = useHistory();

  function routeToListScreen() {
    history.push(BACKOFFICE_PATHS.tenant_promotion_campaigns);
  }

  return (
    <Layout
      button={<ButtonAddTenantPromotionCampaign {...{resetTable, history}} />}
      search={<SearchInput onValue={setSearchQuery} />}>
      <Switch>
        <Route path={`${BACKOFFICE_PATHS.tenant_promotion_campaigns}/new`}>
          <CreateTenantPromotionCampaignDialog onExited={routeToListScreen} />
        </Route>
        <Route path={`${BACKOFFICE_PATHS.tenant_promotion_campaigns}/:id`}>
          <TenantPromotionCampaignDialog onExited={routeToListScreen} />
        </Route>
      </Switch>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(tenant_promotion_campaign) =>
            history.push(
              `${BACKOFFICE_PATHS.tenant_promotion_campaigns}/${tenant_promotion_campaign.id}`,
            )
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

function ButtonAddTenantPromotionCampaign({resetTable, history}) {
  return (
    <ButtonAdd
      title="Create Tenant Promotion Campaign"
      onClick={() => {
        resetTable();
        history.push(`${BACKOFFICE_PATHS.tenant_promotion_campaigns}/new`);
      }}
      className={styles.add_button}
    />
  );
}

const columns = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    text_styles: 'body2',
    sort: 'name',
  },
  {
    id: 'is_default',
    Header: 'Default',
    accessor: 'is_default',
    text_styles: 'body2',
    sort: 'is_default',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
