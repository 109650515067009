import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'DEPOSIT_GUARANTEE_APPLICATION_UPDATED': {
      draft.refresh++;
      break;
    }
  }
}, {});
