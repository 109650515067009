import Input from 'shared/components/Input.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';

import {JsonField} from '../../components/JsonField.js';
import {useResource} from '../../hooks.js';
import DepositGuaranteeApplicationReferenceField from '../../components/reference_field/DepositGuaranteeApplicationReferenceField.js';

export default function VvrbRequestDialog(props) {
  const {id} = useParams();
  const [vvrb_request] = useResource('listVvrbRequests', id);

  const {
    created_at,
    error,
    request,
    response,
    vvrb_process_id,
    deposit_guarantee_application_id,
    path,
    duration_ms,
  } = vvrb_request;

  return (
    <Dialog title="Vvrb Request" {...props}>
      <Input value={id} label="Id" readOnly />
      <Input value={path} label="Path" readOnly />
      <Input value={vvrb_process_id} label="vvrb process id" readOnly />
      <DepositGuaranteeApplicationReferenceField
        id={deposit_guarantee_application_id}
        label="Deposit guarantee application"
      />
      <Input
        value={new Date(created_at).toLocaleString()}
        label="Created at"
        readOnly
      />
      <Input value={error} label="Error" readOnly />
      <Input
        value={duration_ms ? duration_ms / 1000 : null}
        label="Duration in seconds"
        readOnly
      />
      <JsonField value={request} name="request" label="Request" />
      <JsonField value={response} name="response" label="Response" />
    </Dialog>
  );
}
