import Input from 'shared/components/Input.js';
import {formatDate, formatPrice, formatIban} from 'shared/utils.js';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';

import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import RentalContractReferenceField from '../../components/reference_field/RentalContractReferenceField.js';
import DepositGuaranteeApplicationReferenceField from '../../components/reference_field/DepositGuaranteeApplicationReferenceField.js';
import {useResource} from '../../hooks.js';

import ToggleClaimButton from './ToggleClaimButton.js';
import styles from './ClaimDialog.module.scss';

export default function ClaimDialog({...props}) {
  const {id} = useParams();
  const [claim] = useResource('listClaims', id);

  const {is_admin} = useSelector((state) => state.user.account);

  const Footer = (
    <div className={styles.footer}>
      <ToggleClaimButton claim={claim} />
    </div>
  );

  return (
    <Dialog title="Claim" footer={Footer} {...props}>
      {claim.id && <Input value={claim.id} label="ID" readOnly />}
      <RentalContractReferenceField
        id={claim.rental_contract_id}
        label="Rental Contract"
      />
      <ManagerReferenceField id={claim.manager_id} label="Manager" />

      {is_admin && (
        <DepositGuaranteeApplicationReferenceField
          id={claim.deposit_guarantee_application_id}
          label="Tenant"
        />
      )}

      <Input value={claim.comment} label="Comment" readOnly />

      <Input
        value={claim.amount_cents ? formatPrice(claim.amount_cents) : ''}
        label="Amount"
        readOnly
      />

      <Input
        value={
          claim.bank_account_iban ? formatIban(claim.bank_account_iban) : ''
        }
        label="IBAN"
        readOnly
      />

      <Input
        value={claim.bank_account_name}
        label="Bank Account Name"
        readOnly
      />

      <Input
        value={claim?.closed_at ? formatDate(claim.closed_at) : ''}
        label="Closed at"
        readOnly
      />

      <Input
        value={claim?.created_at ? formatDate(claim.created_at) : ''}
        label="Created at"
        readOnly
      />
    </Dialog>
  );
}
