export default function initialState() {
  return {
    modal: {},
    managers: {
      // Use this to retrigger fetches
      refresh: 0,
    },
    user: {
      account: null,
      status: 'loading',
    },
    deposit_guarantees: {
      refresh: 0,
    },
    deposit_guarantee_applications: {
      refresh: 0,
    },
    deposit_accounts: {
      refresh: 0,
    },
    claims: {
      refresh: 0,
    },
    backoffice_users: {
      refresh: 0,
    },
  };
}
