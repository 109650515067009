import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import DepositGuaranteeApplicationReferenceField from '../../components/reference_field/DepositGuaranteeApplicationReferenceField.js';

import styles from './VvrbRequestsScreen.module.scss';
import VvrbRequestDialog from './VvrbRequestDialog.js';

export default function VvrbRequestsScreen() {
  const {
    orderBy,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listVvrbRequests'});

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.vvrb_requests}/:id`}>
        <VvrbRequestDialog
          onExited={() => history.push(BACKOFFICE_PATHS.vvrb_requests)}
          vvrb_requests={rows}
        />
      </Route>
      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(vvrb_request) =>
            history.push(`${BACKOFFICE_PATHS.vvrb_requests}/${vvrb_request.id}`)
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'path',
    Header: 'Path',
    accessor: 'path',
    text_styles: 'body2',
    sort: 'path',
  },
  {
    id: 'vvrb_process_id',
    Header: 'vvrb process id',
    accessor: 'vvrb_process_id',
    text_styles: 'body2',
    sort: 'vvrb_process_id',
  },
  {
    id: 'deposit guarantee application',
    Header: 'Deposit guarantee application',
    accessor: 'deposit_guarantee_application_id',
    Cell: ({value}) => <DepositGuaranteeApplicationReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'deposit_guarantee_application_id',
  },
  {
    id: 'error',
    Header: 'Error',
    accessor: 'error',
    text_styles: 'body2',
    sort: 'error',
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({value}) => (value ? new Date(value).toLocaleString() : ''),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
