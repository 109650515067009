import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';
import {formatShortId} from 'shared/utils.js';

import ReferenceField from './ReferenceField.js';

export default function RentalContractReferenceField({id, ...props}) {
  const history = useHistory();
  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listRentalContracts"
      accessor={(row) => formatShortId(row.short_id)}
      action={(rental_contract) => {
        history.push(
          `${BACKOFFICE_PATHS.rental_contracts}/${rental_contract.id}`,
        );
      }}
    />
  );
}
