import React, {useRef} from 'react';
import Button from 'shared/components/Button.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {addDepositAccountVibans} from '../../actions.js';

export default function UploadVibansButton({deposit_account_id, setVisible}) {
  const fileInputRef = useRef(null);
  const {notify} = useNotify();

  return (
    <>
      <input
        type="file"
        style={{display: 'none'}}
        ref={fileInputRef}
        accept="text/plain"
        onChange={(ev) =>
          handleFileUpload(ev, {deposit_account_id, setVisible, notify})
        }
      />
      <Button
        title="Upload VIBANs"
        onClick={() => {
          fileInputRef.current.click();
        }}
      />
    </>
  );
}

async function handleFileUpload(ev, {deposit_account_id, setVisible, notify}) {
  const ibans_file = ev.target.files[0];
  if (!ibans_file) return;

  const file_content = await ibans_file.text();
  const vibans = file_content
    .split(/\r?\n/)
    .map((str) => str.trim())
    .filter(Boolean);

  try {
    await addDepositAccountVibans({deposit_account_id, vibans});
    notify({text: 'Vibans succesfully uploaded'});
  } catch (err) {
    notify({
      text: `${err.message || err.code.toString()} - ${
        err.data ? JSON.stringify(err.data) : 'no error details'
      }`,
    });
  } finally {
    setVisible(false);
  }
}
