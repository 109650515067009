import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import csv_template_create_deposit_only from 'shared/assets/csv-templates/cash_deposit_import_create_deposit_only.template.csv';
import csv_template_transfer_money_only from 'shared/assets/csv-templates/cash_deposit_import_transfer_money_only.template.csv';

import Layout from '../../components/layout/Layout.js';

import styles from './CashDepositImportScreen.module.scss';
import UploadBulkImportCsvButton from './UploadBulkImportCsvButton.js';

export default function CashDepositImportScreen() {
  return (
    <Layout button={<UploadBulkImportCsvButton />}>
      <div className={styles.content}>
        <h1
          className={classNames(
            text_styles.huge_headline_left,
            styles.heading,
          )}>
          Cash Deposit Import
        </h1>

        <p>
          <a
            className={classNames(text_styles.body2, styles.link)}
            href="https://www.notion.so/getmomo/CSV-file-format-bulk-import-cad651fa46ae4ad4887824dc427293f7"
            target="_blank"
            rel="noreferrer">
            Documentation
          </a>
        </p>
        <p>
          <a
            className={classNames(text_styles.body2, styles.link)}
            href={csv_template_create_deposit_only}
            target="_blank"
            download="create_deposit_only.template.csv"
            rel="noreferrer">
            Download the CSV template: Create Deposit Only
          </a>
        </p>
        <p>
          <a
            className={classNames(text_styles.body2, styles.link)}
            href={csv_template_transfer_money_only}
            target="_blank"
            download="transfer_money_only.template.csv"
            rel="noreferrer">
            Download the CSV template: Transfer Money Only
          </a>
        </p>
      </div>
    </Layout>
  );
}
