import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import DateInput from 'shared/components/DateInput';
import Input from 'shared/components/Input.js';
import Checkbox from 'shared/components/checkbox/Checkbox.js';
import Button from 'shared/components/Button.js';
import {setFormErrors} from 'shared/effects.js';
import IconButton from 'shared/components/IconButton.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {updateTenantPromotionCampaign} from '../../actions.js';
import {alert, handleError, confirmClose} from '../../effects.js';
import {ReactComponent as EditSvg} from '../../assets/edit.svg';
import {useResource} from '../../hooks.js';

import styles from './TenantPromotionCampaignDialog.module.scss';

export default function TenantPromotionCampaignDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const [tenant_promotion_campaign] = useResource(
    'listTenantPromotionCampaigns',
    id,
  );
  const {notify} = useNotify();

  const [readOnly, setReadOnly] = useState(true);
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset(tenant_promotion_campaign);
  }, [reset, tenant_promotion_campaign]);

  const onSubmit = handleSubmit((fields) => {
    return proceed({
      fields,
      tenant_promotion_campaign,
      setError,
      setVisible,
      setReadOnly,
      notify,
    });
  });

  const Footer = (
    <div className={styles.footer}>
      {!readOnly && (
        <Button
          title="Save"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      )}
    </div>
  );

  function EditIcon() {
    return (
      <IconButton onClick={() => setReadOnly(false)}>
        <EditSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Tenant Promotion Campaign"
      show={visible}
      footer={Footer}
      additionalIcons={
        tenant_promotion_campaign?.id && [readOnly && <EditIcon />]
      }
      onHide={() => confirmClose({isDirty})}
      {...props}>
      {tenant_promotion_campaign?.id && (
        <Input value={tenant_promotion_campaign.id} label="ID" readOnly />
      )}
      <Input
        label="Name"
        readOnly={readOnly}
        {...register('name')}
        error={errors.name?.message}
      />
      <Input
        label="Header"
        readOnly={readOnly}
        {...register('header')}
        error={errors.header?.message}
      />
      <Input
        label="Body"
        readOnly={readOnly}
        {...register('body')}
        error={errors.body?.message}
      />
      {readOnly && (
        <DateInput
          value={tenant_promotion_campaign?.created_at || null}
          label="Created at"
          readOnly
        />
      )}

      <Controller
        control={control}
        name="is_default"
        render={({field: {value, onChange, name}}) => (
          <Checkbox
            id={name}
            value={value}
            onChange={onChange}
            label="Default tenant promotion campaign"
            disabled={readOnly}
            error={errors[name]?.message}
          />
        )}
      />
    </Dialog>
  );
}

async function proceed({
  fields,
  tenant_promotion_campaign,
  setError,
  setVisible,
  setReadOnly,
  notify,
}) {
  try {
    await updateTenantPromotionCampaign({
      id: tenant_promotion_campaign.id,
      ...fields,
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'The changes have been saved.'});
  setVisible(false);
  setReadOnly(true);
}
