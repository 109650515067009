import {BANKS} from '../constants.js';

export function displayBankName(bank) {
  if (!bank) {
    throw new Error('bank is required');
  }

  if (bank === BANKS.ten31) {
    return 'TEN31 Bank AG';
  }
  if (bank === BANKS.hcob) {
    return 'Hamburg Commercial Bank AG';
  }

  throw new Error('unknown bank');
}
