import React, {useState, useEffect} from 'react';
import useSWR from 'swr';
import {RPC} from 'shared/api.js';
import Icon from 'shared/components/Icon.js';

import {LabelWrapper} from '../label_wrapper/LabelWrapper.js';

import {ReactComponent as ErrorSvg} from './error.svg';
import styles from './ReferenceField.module.scss';

const FETCHING_TEXT = 'fetching…';

export default function ReferenceField({
  rpcListMethod,
  id,
  accessor,
  action,
  label,
}) {
  const [row, setRow] = useState(null);
  const [display, setDisplay] = useState(FETCHING_TEXT);

  const {data, error, isLoading} = useSWR(
    [rpcListMethod, id],
    async (method, id) => {
      if (!id || !method) throw new Error('Method and ID need to be defined');
      return RPC(method, {
        filter: {
          id: [id],
        },
      });
    },
  );

  useEffect(() => {
    if (isLoading) {
      setDisplay(FETCHING_TEXT);
      return;
    }

    const {rows, total} = data ?? {};

    if (error || total !== 1) {
      setDisplay(null);
      return;
    }

    const [row] = rows;
    setRow(row);

    switch (typeof accessor) {
      case 'string': {
        setDisplay(row[accessor]);
        break;
      }
      case 'function': {
        setDisplay(accessor(row));
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.error('accessor needs to be a string or a function');
        setDisplay(null);
      }
    }

    return () => {
      setDisplay(FETCHING_TEXT);
    };
  }, [accessor, data, error, isLoading]);

  return (
    <LabelWrapper label={label}>
      {!id && <span>…</span>}
      {!display && id && (
        <div className={styles.error}>
          <Icon>
            <ErrorSvg className={styles.icon} />
          </Icon>
          <span>Reference Failure</span>
        </div>
      )}
      {display && id && (
        <SimpleDisplay row={row} display={display} action={action} />
      )}
    </LabelWrapper>
  );
}

function SimpleDisplay({row, display, action}) {
  return (
    <span
      className={styles.simple_display}
      role="button"
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (row) {
          action?.(row);
        }
      }}>
      {display}
    </span>
  );
}
