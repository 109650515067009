import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import SearchInput from '../../components/search_input/SearchInput.js';

import CovivioEventDialog from './CovivioEventDialog.js';
import styles from './CovivioEventsScreen.module.scss';

export default function CovivioEventsScreen() {
  const {
    orderBy,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
    setSearchQuery,
  } = useFetchTableData({
    method: 'listCovivioEvents',
  });

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.covivio_events}/:id`}>
        <CovivioEventDialog
          onExited={() => history.push(BACKOFFICE_PATHS.covivio_events)}
        />
      </Route>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(covivio_event) =>
            history.push(
              `${BACKOFFICE_PATHS.covivio_events}/${covivio_event.id}`,
            )
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'id',
    Header: 'Id',
    accessor: 'id',
    text_styles: 'body2',
    sort: 'id',
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({value}) => new Date(value).toLocaleString(),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
