import {formatDate, formatPrice, formatShortId} from 'shared/utils.js';
import {useSelector} from 'react-redux';
import {useHistory, Route} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import DepositGuaranteeApplicationsReferenceField from '../../components/reference_field/DepositGuaranteeApplicationReferenceField.js';

import styles from './DepositsScreen.module.scss';
import DepositDialog from './DepositDialog.js';

export default function DepositsScreen() {
  const {refresh} = useSelector((state) => state.deposit_guarantees);

  const {
    orderBy,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listDeposits', refresh});

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.deposits}/:id`}>
        <DepositDialog
          onExited={() => history.push(BACKOFFICE_PATHS.deposits)}
        />
      </Route>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(deposit) => {
            history.push(`${BACKOFFICE_PATHS.deposits}/${deposit.id}`);
          }}
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'short_id',
    Header: 'Momo-ID',
    accessor: (row) => formatShortId(row.short_id),
    text_styles: 'body2',
    sort: 'short_id',
  },
  {
    id: 'manager_id',
    Header: 'Manager',
    accessor: 'manager_id',
    Cell: ({value}) => <ManagerReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'manager_id',
  },
  {
    id: 'tenant_last_name',
    Header: 'Tenant name',
    accessor: (row) => {
      return row.tenant_display_name;
    },
    text_styles: 'body2',
    sort: 'tenant_last_name',
  },
  {
    id: 'deposit_guarantee_application_id',
    Header: 'Deposit guarantee application',
    accessor: 'deposit_guarantee_application_id',
    Cell: ({value}) =>
      value ? <DepositGuaranteeApplicationsReferenceField id={value} /> : '',
    text_styles: 'body2',
    sort: 'deposit_guarantee_application_id',
  },
  {
    id: 'deposit_amount_cents',
    Header: 'Deposit',
    accessor: 'deposit_amount_cents',
    Cell: ({value}) => (value ? formatPrice(value) : ''),
    text_styles: 'body2',
    sort: 'deposit_amount_cents',
  },
  {
    id: 'depositGuaranteeActive',
    Header: 'Deposit Guarantee Active',
    accessor: 'depositGuaranteeActive',
    text_styles: 'body2',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
