import React, {useState} from 'react';
import Button from 'shared/components/Button';
import {useNotify} from 'shared/NotifyProvider.js';

import {rejectInvitation} from '../../actions.js';

export default function ApplicationRejectedByTenantButton({
  deposit_guarantee_application,
}) {
  const [loading, setLoading] = useState(false);
  const {notify} = useNotify();

  const {rental_contract_id, accepted_at, rejected_at} =
    deposit_guarantee_application;

  async function onClick() {
    setLoading(true);
    try {
      await rejectInvitation({
        rental_contract_id,
      });
    } catch (err) {
      notify({text: err.message || err.code.toString()});
      return;
    } finally {
      setLoading(false);
    }

    notify({
      text: 'Invitation has been rejected and invitation reminders stopped',
    });
  }

  if (accepted_at) return null;
  if (rejected_at) return null;

  return (
    <Button
      title="Reject Invitation on Behalf of Tenant"
      onClick={onClick}
      disabled={loading}
    />
  );
}
