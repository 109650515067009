import {Link} from 'react-router-dom';
import text_styles from 'shared/styles/text_styles.module.scss';
import Placeholder from 'shared/components/combobox/Placeholder.js';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Combobox from './Combobox.js';

export default function SelectTenantPromotionCampaign({
  name,
  value,
  onChange,
  selectedItemListener,
  error,
  label,
  readOnly,
  placeholder = '…',
}) {
  return (
    <Combobox
      name={name}
      value={value}
      onChange={onChange}
      selectedItemListener={selectedItemListener}
      placeholder={placeholder}
      rpc_method="listTenantPromotionCampaigns"
      DropdownItem={DropdownItem}
      itemToString={itemToString}
      error={error}
      NoResultsPlaceholder={NoResultsPlaceholder}
      NoSearchResultsPlaceholder={NoSearchResultsPlaceholder}
      label={label}
      readOnly={readOnly}
    />
  );
}

function DropdownItem({item}) {
  return (
    <p className={text_styles.body1_bold_left}>
      {item?.name}
      {item.is_default ? <>{' (default)'}</> : null}
    </p>
  );
}

function itemToString(item) {
  return item?.id ? `${item.name}` : '';
}

function NoResultsPlaceholder() {
  return (
    <Placeholder>
      <p>There are no tenant promotion campaigns, yet.</p>
      <p>
        You can create one{' '}
        <Link to={`${BACKOFFICE_PATHS.tenant_promotion_campaigns}/new`}>
          here
        </Link>
        .
      </p>
    </Placeholder>
  );
}

function NoSearchResultsPlaceholder({search_query}) {
  return (
    <Placeholder>
      <p>
        We could not find a tenant promotion campaign with query „{search_query}
        “.
      </p>
      <p>
        You can create one{' '}
        <Link to={`${BACKOFFICE_PATHS.tenant_promotion_campaigns}/new`}>
          here
        </Link>
        .
      </p>
    </Placeholder>
  );
}
