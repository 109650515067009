import {useSelector} from 'react-redux';
import {formatDate} from 'shared/utils.js';
import {useHistory, Switch, Route} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';

import ManagerDialog from './ManagerDialog.js';
import styles from './ManagersScreen.module.scss';
import CreateManagerDialog from './CreateManagerDialog.js';

export default function ManagersScreen() {
  const {refresh} = useSelector((state) => state.managers);

  const {
    orderBy,
    resetTable,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listManagers', refresh});

  const history = useHistory();

  function routeToListScreen() {
    history.push(BACKOFFICE_PATHS.managers);
  }

  return (
    <Layout
      button={<ButtonAddManager {...{resetTable, history}} />}
      search={<SearchInput onValue={setSearchQuery} />}>
      <Switch>
        <Route path={`${BACKOFFICE_PATHS.managers}/new`}>
          <CreateManagerDialog onExited={routeToListScreen} />
        </Route>
        <Route path={`${BACKOFFICE_PATHS.managers}/:id`}>
          <ManagerDialog onExited={routeToListScreen} />
        </Route>
      </Switch>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(manager) =>
            history.push(`${BACKOFFICE_PATHS.managers}/${manager.id}`)
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

function ButtonAddManager({resetTable, history}) {
  return (
    <ButtonAdd
      title="Create Manager"
      onClick={() => {
        resetTable();
        history.push(`${BACKOFFICE_PATHS.managers}/new`);
      }}
      className={styles.add_button}
    />
  );
}

const columns = [
  {
    id: 'last_name',
    Header: 'Name',
    accessor: (row) => {
      return `${row.first_name} ${row.last_name}`;
    },
    text_styles: 'body2',
    sort: 'last_name',
  },
  {
    id: 'company_name',
    Header: 'Company Name',
    accessor: 'company_name',
    text_styles: 'body2',
    sort: 'company_name',
  },
  {
    id: 'email_address',
    Header: 'Email',
    accessor: 'email_address',
    Cell: ({value}) => <a href={`mailto:${value}`}>{value}</a>,
    text_styles: 'body2',
    sort: 'email_address',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
  {
    id: 'email_verified_at',
    Header: 'Email verified at',
    accessor: 'email_verified_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'email_verified_at',
  },
  {
    id: 'first_login_at',
    Header: 'First login at',
    accessor: 'first_login_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'first_login_at',
  },
];
