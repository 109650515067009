import Input from 'shared/components/Input.js';
import TextArea from 'shared/components/TextArea';
import SeparatorLine from 'shared/components/SeparatorLine';
import {
  formatDate,
  formatPrice,
  formatShortId,
  isDepositGuaranteePending,
} from 'shared/utils';
import translateSubStatus from 'shared/ui/translateSubStatus.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';

import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import RentalContractReferenceField from '../../components/reference_field/RentalContractReferenceField.js';
import {useResource} from '../../hooks.js';

import styles from './DepositDialog.module.scss';
import SendInvitationButton from './SendInvitationButton.js';
import RefreshInvitationButton from './RefreshInvitationButton.js';
import ResetOnboardingButton from './ResetOnboardingButton.js';

export default function DepositDialog({...props}) {
  const {id} = useParams();
  const [deposit] = useResource('listDeposits', id);

  const Footer = (
    <div className={styles.footer}>
      <div>
        <div className={styles.status_row}>
          <div className={text_styles.body1_bold_left}>
            Status (Manager app):
          </div>
          <div className={text_styles.body2}>{deposit?.status}</div>

          <div className={text_styles.body1_bold_left}>
            Sub-status (Manager app):
          </div>
          <div className={text_styles.body2}>
            {deposit?.sub_status
              ? translateSubStatus({
                  sub_status: deposit.sub_status,
                  claims: deposit.claims,
                  deposit_guarantee_start_date:
                    deposit.deposit_guarantee_start_date,
                  account_balance_cents: deposit.account_balance_cents,
                })
              : '–'}
          </div>
        </div>
        {isDepositGuaranteePending({deposit}) &&
          !deposit.deposit_guarantee_accepted_at && (
            <>
              {deposit.tenant_phone_number &&
                deposit.deposit_guarantee_application_id && (
                  <div className={styles.button_row}>
                    <SendInvitationButton
                      deposit_guarantee_application_id={
                        deposit.deposit_guarantee_application_id
                      }
                    />
                    <RefreshInvitationButton
                      deposit_guarantee_application_id={
                        deposit.deposit_guarantee_application_id
                      }
                    />
                  </div>
                )}
              <div className={styles.button_row}>
                {deposit.deposit_guarantee_application_id && (
                  <ResetOnboardingButton
                    deposit_guarantee_application_id={
                      deposit.deposit_guarantee_application_id
                    }
                  />
                )}
              </div>
            </>
          )}
      </div>
    </div>
  );

  return (
    <Dialog title="Deposit" footer={Footer} {...props}>
      <div className={styles.two_columns}>
        <Input value={deposit?.rental_contract_id || ''} label="ID" readOnly />
        <Input
          value={deposit?.short_id ? formatShortId(deposit?.short_id) : ''}
          label="Momo-ID"
          readOnly
        />

        <RentalContractReferenceField
          id={deposit?.rental_contract_id}
          label="Rental contract"
        />

        <ManagerReferenceField id={deposit?.manager_id} label="Manager" />

        <Input
          value={deposit?.tenant_display_name}
          label="Tenant name"
          readOnly
        />

        <Input value={deposit?.deposit_type} label="Deposit type" readOnly />
      </div>

      <SeparatorLine />

      <div className={styles.two_columns}>
        <Input
          value={deposit?.postal_code || ''}
          label="Postal code"
          readOnly
        />
        <Input value={deposit?.region || ''} label="Region" readOnly />

        <Input
          value={deposit?.property_type || ''}
          label="Property type"
          readOnly
        />
        <Input value={deposit?.storey || ''} label="Storey" readOnly />

        <Input
          value={deposit?.street_name || ''}
          label="Street name"
          readOnly
        />
        <Input
          value={deposit?.street_number || ''}
          label="Street number"
          readOnly
        />

        <Input
          value={deposit?.tenant_phone_number || ''}
          label="Tenant phone number"
          readOnly
        />
        <Input
          value={deposit?.property_owner_display_name || ''}
          label="Property owner"
          readOnly
        />
      </div>

      <SeparatorLine />

      <div className={styles.two_columns}>
        <Input
          value={
            deposit.cold_rent_cents ? formatPrice(deposit.cold_rent_cents) : ''
          }
          label="Cold rent"
          readOnly
        />
        <Input
          value={
            deposit?.deposit_amount_cents
              ? formatPrice(deposit.deposit_amount_cents)
              : ''
          }
          label="Deposit"
          readOnly
        />

        <Input
          value={deposit?.start_date ? formatDate(deposit.start_date) : ''}
          label="Start date"
          readOnly
        />
        <Input
          value={deposit?.created_at ? formatDate(deposit.created_at) : ''}
          label="Created at"
          readOnly
        />
      </div>

      <Input
        value={deposit?.released_at ? formatDate(deposit.released_at) : ''}
        label="Deposit released at"
        readOnly
      />

      <Input
        value={deposit?.disabled_at ? formatDate(deposit.disabled_at) : ''}
        label="Deposit guarantee disabled at"
        readOnly
      />

      <SeparatorLine />

      <Input
        value={deposit?.given_reference || ''}
        label="Given reference"
        readOnly
      />

      <TextArea value={deposit?.notes || ''} label="Notizen" readOnly />

      <SeparatorLine />

      <Input
        value={deposit?.tenant_invitation_url || ''}
        label="Tenant invitation url"
        readOnly
      />
    </Dialog>
  );
}
