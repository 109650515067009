import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function DepositGuaranteeApplicationReferenceField({
  id,
  ...props
}) {
  const history = useHistory();

  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listDepositGuaranteeApplications"
      accessor="id"
      action={(deposit_guarantee_application) => {
        history.push(
          `${BACKOFFICE_PATHS.deposit_guarantee_applications}/${deposit_guarantee_application.id}`,
        );
      }}
    />
  );
}
