import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function TenantPromotionCampaignReferenceField({id, ...props}) {
  const history = useHistory();
  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listTenantPromotionCampaigns"
      accessor={(item) => `${item.name}${item.is_default ? ' (default)' : ''}`}
      action={(tenant_promotion_campaign) => {
        history.push(
          `${BACKOFFICE_PATHS.tenant_promotion_campaigns}/${tenant_promotion_campaign.id}`,
        );
      }}
    />
  );
}
