import {RPC} from 'shared/api.js';

import store from './store/store.js';
import {reportError} from './effects.js';

export async function createManager(params) {
  const payload = await RPC('createManager', params);

  store.dispatch({
    type: 'MANAGER_CREATED',
  });
  return payload;
}

export async function updateManager(params) {
  const payload = await RPC('updateManager', params);

  store.dispatch({
    type: 'MANAGER_UPDATED',
  });
  return payload;
}

export async function deleteManager(id) {
  const payload = {id};
  await RPC('deleteManager', payload);

  store.dispatch({
    type: 'MANAGER_DELETED',
  });
}

export async function login(params) {
  const {account} = await RPC('login', params);

  store.dispatch({
    type: 'LOGGED_IN',
    payload: {
      account,
    },
  });
}

export async function updateRentalContract(params) {
  const payload = await RPC('updateRentalContract', params);

  store.dispatch({type: 'RENTAL_CONTRACT_UPDATED'});
  return payload;
}

export async function saveRentalContractDocuments(params = {}) {
  const payload = await RPC('saveRentalContractDocuments', params);

  store.dispatch({type: 'DEPOSIT_GUARANTEE_APPLICATION_UPDATED'});
  return payload;
}

export async function createDepositAccount(params = {}) {
  const payload = await RPC('createDepositAccount', params);

  store.dispatch({type: 'DEPOSIT_ACCOUNT_CREATED'});

  return payload;
}

export async function updateDepositAccount(params = {}) {
  const payload = await RPC('updateDepositAccount', params);

  store.dispatch({type: 'DEPOSIT_ACCOUNT_UPDATED'});

  return payload;
}

export async function addDepositAccountVibans(params = {}) {
  const payload = await RPC('addDepositAccountVibans', params);

  store.dispatch({type: 'DEPOSIT_ACCOUNT_UPDATED'});

  return payload;
}

export async function createTenantPromotionCampaign(params) {
  const payload = await RPC('createTenantPromotionCampaign', params);

  store.dispatch({
    type: 'TENANT_PROMOTION_CAMPAIGN_CREATED',
  });
  return payload;
}

export async function updateTenantPromotionCampaign(params) {
  const payload = await RPC('updateTenantPromotionCampaign', params);

  store.dispatch({
    type: 'TENANT_PROMOTION_CAMPAIGN_UPDATED',
  });
  return payload;
}

export async function createBackofficeUser(params = {}) {
  const payload = await RPC('createBackofficeUser', params);

  store.dispatch({
    type: 'BACKOFFICE_USER_CREATED',
  });

  return payload;
}

export async function updateBackofficeUser(params = {}) {
  const payload = await RPC('updateBackofficeUser', params);

  store.dispatch({
    type: 'BACKOFFICE_USER_UPDATED',
  });

  return payload;
}

export async function updateDepositGuaranteeApplication(params = {}) {
  const payload = await RPC('updateDepositGuaranteeApplication', params);

  store.dispatch({
    type: 'DEPOSIT_GUARANTEE_APPLICATION_UPDATED',
  });
  return payload;
}

export async function rejectInvitation(params = {}) {
  const payload = await RPC('rejectInvitation', params);

  store.dispatch({
    type: 'DEPOSIT_GUARANTEE_APPLICATION_UPDATED',
  });

  return payload;
}

export async function reviewDepositGuaranteeApplication(params = {}) {
  const payload = await RPC('reviewDepositGuaranteeApplication', params);

  store.dispatch({
    type: 'DEPOSIT_GUARANTEE_APPLICATION_UPDATED',
  });

  return payload;
}

export async function setDepositGuaranteeApplicationAcceptedByProvider(
  params = {},
) {
  const payload = await RPC(
    'setDepositGuaranteeApplicationAcceptedByProvider',
    params,
  );

  store.dispatch({
    type: 'DEPOSIT_GUARANTEE_APPLICATION_UPDATED',
  });

  return payload;
}

export async function setDepositGuaranteeApplicationRejectedByProvider(
  params = {},
) {
  const payload = await RPC(
    'setDepositGuaranteeApplicationRejectedByProvider',
    params,
  );

  store.dispatch({
    type: 'DEPOSIT_GUARANTEE_APPLICATION_UPDATED',
  });

  return payload;
}

export async function closeClaim(params = {}) {
  const payload = await RPC('closeClaim', params);

  store.dispatch({
    type: 'CLAIM_CLOSED',
  });

  return payload;
}

export async function openClaim(params = {}) {
  const payload = await RPC('openClaim', params);

  store.dispatch({
    type: 'CLAIM_OPENED',
  });

  return payload;
}

export async function bootstrap() {
  const {account} = await RPC('bootstrap');

  store.dispatch({
    type: 'BOOTSTRAPPED',
    payload: account,
  });
}

export async function logout(history) {
  try {
    await RPC('logout');
  } catch (err) {
    reportError(err);
  }
  store.dispatch({
    type: 'RESET',
  });
  // Ensure that LOGGED_OUT remains after RESET
  store.dispatch({
    type: 'LOGGED_OUT',
  });
  history.push({
    pathname: '/',
  });
}
