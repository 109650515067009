import React from 'react';
import classNames from 'classnames';

import Sidebar from './Sidebar.js';
import styles from './Layout.module.scss';

export default function Layout({button, search, children, className}) {
  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.main}>
        <div className={styles.header}>
          {button}
          {search}
        </div>
        <div className={classNames(styles.content, className)}>{children}</div>
      </div>
    </div>
  );
}
