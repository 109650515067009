import React, {useState} from 'react';
import Button from 'shared/components/Button';
import {useNotify} from 'shared/NotifyProvider.js';

import {closeClaim, openClaim} from '../../actions.js';

export default function ToggleClaimButton({claim}) {
  const [loading, setLoading] = useState(false);
  const {notify} = useNotify();

  const isClosed = !!claim.closed_at;
  const successMessage = `The claim has been ${
    isClosed ? 'reopened' : 'closed'
  }`;

  async function onClick() {
    setLoading(true);
    try {
      isClosed
        ? await openClaim({id: claim.id})
        : await closeClaim({id: claim.id});
    } catch (err) {
      notify({text: err.message || err.code.toString()});
      return;
    }
    notify({text: successMessage});
    setLoading(false);
  }

  const title = isClosed ? 'Reopen' : 'Close';
  return <Button title={title} onClick={onClick} disabled={loading} />;
}
