import {useHistory} from 'react-router-dom';
import {RPC} from 'shared/api.js';
import {uploadToMinio} from 'shared/utils.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import {BACKOFFICE_PATHS} from 'shared/constants.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {handleError} from '../../effects.js';
import {saveRentalContractDocuments} from '../../actions.js';

import styles from './UploadRentalContractDocumentButton.module.scss';

export default function UploadRentalContractDocumentButton({
  deposit_guarantee_application_id,
}) {
  const history = useHistory();
  const {notify} = useNotify();

  return (
    <>
      <label htmlFor="rental_contract_document">
        <div
          className={classNames(styles.button, text_styles.button_bold_bright)}>
          Upload rental contract document
        </div>
      </label>
      <input
        id="rental_contract_document"
        name="rental_contract_document"
        type="file"
        accept=".jpg, .jpeg, .png, .pdf"
        hidden
        onClick={(e) => (e.target.value = null)}
        onChange={(e) =>
          uploadFiles({
            files: [...e.target.files],
            deposit_guarantee_application_id,
            history,
            notify,
          })
        }
      />
    </>
  );
}

async function uploadFile({file}) {
  let result;
  try {
    result = await RPC('getTenantUploadUrl', {filename: file.name});
    const {id, filename, upload_url} = result;

    await uploadToMinio(file, upload_url);

    return {id, filename};
  } catch (err) {
    handleError(err);
  }
}

async function uploadFiles({
  files,
  deposit_guarantee_application_id,
  history,
  notify,
}) {
  const rental_contract_documents = [];

  for (const file of files) {
    const {id, filename} = await uploadFile({file});
    rental_contract_documents.push({id, filename});
  }

  try {
    await saveRentalContractDocuments({
      deposit_guarantee_application_id,
      rental_contract_documents,
    });
  } catch (err) {
    handleError(err);
  } finally {
    notify({
      text: 'Rental contract document uploaded',
    });
    history.push(BACKOFFICE_PATHS.deposit_guarantee_applications);
  }
}
