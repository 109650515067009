import {useEffect, useState, useCallback} from 'react';
import useSWR from 'swr';
import {RPC} from 'shared/api.js';

import {handleError} from './effects.js';

export function useResource(method, id) {
  const {data} = useSWR(id ? [id] : null, (id) =>
    RPC(method, {filter: {id: [id]}}),
  );

  const resource = data?.rows[0] || {};

  return [resource];
}

function useFetchRefresh({
  method,
  search_query,
  per_page,
  refresh,
  sort_column,
  sort_direction,
}) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  // Reset pagination state when search query changes
  useEffect(() => {
    setOffset(0);
    setTotal(0);
  }, [search_query]);

  const memo_fetcher = useCallback(() => {
    setLoading(true);
    setResult();

    RPC(method, {
      range: [offset, per_page],
      filter: {
        q: search_query,
      },
      sort: [sort_column, sort_direction],
    })
      .then((result) => {
        setTotal(result.total);
        setResult(result);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [
    method,
    search_query,
    offset,
    per_page,
    sort_column,
    sort_direction,
    setTotal,
  ]);

  useEffect(() => {
    memo_fetcher();
  }, [memo_fetcher, refresh]);

  return {
    loading,
    rows: result?.rows || [],
    total,
    offset,
    setOffset,
  };
}

export function useFetchTableData({
  default_sort_column = 'created_at',
  default_sort_direction = 'DESC',
  method,
  refresh,
}) {
  const entries_per_page = 10;
  const [search_query, setSearchQuery] = useState('');
  const [sort_column, setSortColumn] = useState(default_sort_column);
  const [sort_direction, setSortDirection] = useState(default_sort_direction);

  const {loading, rows, total, offset, setOffset} = useFetchRefresh({
    method,
    search_query,
    per_page: entries_per_page,
    refresh,
    sort_column,
    sort_direction,
  });

  function resetTable() {
    setSortColumn(default_sort_column);
    setSortDirection(default_sort_direction);
    setOffset(0);
  }

  function orderBy(field) {
    if (sort_column === field) {
      sort_direction === 'DESC'
        ? setSortDirection('ASC')
        : setSortDirection('DESC');
    } else {
      setSortColumn(field);
      setSortDirection('ASC');
    }
  }

  return {
    orderBy,
    resetTable,
    search_query,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  };
}
