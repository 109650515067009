import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'DEPOSIT_ACCOUNT_CREATED':
    case 'DEPOSIT_ACCOUNT_UPDATED': {
      draft.refresh++;
      break;
    }
  }
}, {});
