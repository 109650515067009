import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function DepositAccountReferenceField({id, ...props}) {
  const history = useHistory();
  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listDepositAccounts"
      accessor={({id}) => id}
      action={(deposit_account) => {
        history.push(
          `${BACKOFFICE_PATHS.deposit_accounts}/${deposit_account.id}`,
        );
      }}
    />
  );
}
