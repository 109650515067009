import {useEffect, useState} from 'react';
import {request, RPC} from 'shared/api.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import {formatPrice, toLocaleDate} from 'shared/utils.js';
import SeparatorLine from 'shared/components/SeparatorLine.js';
import Select from 'shared/components/Select.js';
import Button from 'shared/components/Button.js';
import DateInput from 'shared/components/DateInput.js';
import {Controller, useForm} from 'react-hook-form';

import Layout from '../../components/layout/Layout.js';
import {handleError} from '../../effects.js';

import styles from './UnallocatedPaymentsScreen.module.scss';

export default function UnallocatedPaymentsScreen() {
  const {REACT_APP_DEFAULT_UNALLOCATED_PAYMENTS_START_DATE} = process.env;
  const [unallocated_payments, setUnallocatedPayments] = useState(null);
  const [deposit_account_id, setDepositAccountId] = useState(null);

  const {
    control,
    formState: {errors},
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      start_date: REACT_APP_DEFAULT_UNALLOCATED_PAYMENTS_START_DATE,
    },
  });

  const start_date = watch('start_date');

  useEffect(() => {
    RPC('listUnallocatedPayments', {start_date})
      .then(setUnallocatedPayments)
      .catch((err) => {
        handleError(err);
      });
  }, [start_date]);

  if (!unallocated_payments) {
    return <Layout>Loading data...</Layout>;
  }

  const deposit_account_select_options =
    unallocated_payments?.deposit_accounts.map(
      ({
        id,
        display_name,
        iban,
        unallocated_payments_count,
        unallocated_payments_sum,
      }) => {
        return {
          id,
          name: `${display_name} | ${iban} | Unallocated Payments: ${unallocated_payments_count} (${formatPrice(
            Number(unallocated_payments_sum),
          )})`,
        };
      },
    );

  return (
    <Layout>
      <div className={styles.content}>
        <h1
          className={classNames(
            text_styles.huge_headline_left,
            styles.heading,
          )}>
          Unallocated Payments
        </h1>

        <div className={styles.details_text}>
          <p>
            Unallocated payments:{' '}
            {unallocated_payments?.unallocated_payments_count || 0} (
            {formatPrice(
              Number(unallocated_payments?.unallocated_payments_sum) || 0,
            )}
            )
          </p>
          <p>
            Deposit accounts with unallocated payments:{' '}
            {unallocated_payments?.deposit_accounts?.length || 0}
          </p>
        </div>
        <Controller
          name="start_date"
          control={control}
          render={({field: {value, onChange, name}}) => (
            <DateInput
              className={styles.date_input}
              value={value}
              onChange={onChange}
              label="Start date"
              error={errors[name]?.message}
              name={name}
            />
          )}
        />
        <SeparatorLine />
        <Select
          label="Deposit account"
          options={deposit_account_select_options}
          truncate_length={0}
          onChange={(event) => setDepositAccountId(event.target.value)}
        />

        {deposit_account_id && (
          <div className={styles.download_data}>
            <div>
              Unallocated Payments:{' '}
              {
                unallocated_payments?.deposit_accounts.find(
                  ({id}) => id === deposit_account_id,
                ).unallocated_payments_count
              }{' '}
              | Virtual accounts:{' '}
              {
                unallocated_payments?.deposit_accounts.find(
                  ({id}) => id === deposit_account_id,
                ).number_of_allocated_vibans
              }
            </div>
            <DownloadUnallocatedPaymentsDataButton
              deposit_account_id={deposit_account_id}
              deposit_accounts={unallocated_payments?.deposit_accounts}
              start_date={start_date}
            />
          </div>
        )}
      </div>
    </Layout>
  );
}

function DownloadUnallocatedPaymentsDataButton({
  deposit_account_id,
  deposit_accounts,
  start_date,
}) {
  const {display_name} = deposit_accounts.find(
    ({id}) => id === deposit_account_id,
  );
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    try {
      const response = await request(
        `downloadUnallocatedPaymentsData/${deposit_account_id}?start_date=${start_date}`,
        {
          method: 'GET',
          credentials: 'include',
        },
      );

      const filename = `${toLocaleDate(
        new Date(),
      )} ${display_name} Unallocated Payments.zip`;
      await downloadStatementFromResponse(response, filename);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }

  return <Button title="Download Data" onClick={onClick} disabled={loading} />;
}

async function downloadStatementFromResponse(response, filename) {
  const objectUrl = URL.createObjectURL(await response.blob());
  const a = document.createElement('a');
  a.setAttribute('href', objectUrl);
  a.setAttribute('download', filename);
  a.setAttribute('target', '_blank');
  document.body.append(a);
  a.click();
  a.remove();
}
