import React from 'react';
import ReactJson from 'react-json-view';

import {LabelWrapper} from './label_wrapper/LabelWrapper.js';

export function JsonField({label, value, ...props}) {
  return (
    <LabelWrapper label={label}>
      <ReactJson collapsed quotesOnKeys={false} {...props} src={value} />
    </LabelWrapper>
  );
}
