import {formatDate, formatPrice} from 'shared/utils.js';
import {useSelector} from 'react-redux';
import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import DepositReferenceField from '../../components/reference_field/DepositReferenceField.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';

import DepositGuaranteeApplicationDialog from './DepositGuaranteeApplicationDialog.js';
import styles from './DepositGuaranteeApplicationsScreen.module.scss';

export default function DepositGuaranteeApplicationsScreen() {
  const {refresh} = useSelector(
    (state) => state.deposit_guarantee_applications,
  );

  const {
    orderBy,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listDepositGuaranteeApplications', refresh});

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.deposit_guarantee_applications}/:id`}>
        <DepositGuaranteeApplicationDialog
          onExited={() =>
            history.push(BACKOFFICE_PATHS.deposit_guarantee_applications)
          }
        />
      </Route>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(deposit_guarantee_application) =>
            history.push(
              `${BACKOFFICE_PATHS.deposit_guarantee_applications}/${deposit_guarantee_application.id}`,
            )
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'provider',
    Header: 'Provider',
    accessor: 'provider',
    text_styles: 'body2',
    sort: 'provider',
  },
  {
    id: 'deposit_id',
    Header: 'Deposit',
    accessor: 'deposit_id',
    Cell: ({value}) => <DepositReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'deposit_id',
  },
  {
    id: 'manager_id',
    Header: 'Manager',
    accessor: 'manager_id',
    Cell: ({value}) => <ManagerReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'manager_id',
  },
  {
    id: 'monthly_fee_cents',
    Header: 'Monthly fee',
    accessor: 'monthly_fee_cents',
    Cell: ({value}) => (value ? formatPrice(value) : ''),
    text_styles: 'body2',
    sort: 'monthly_fee_cents',
  },
  {
    id: 'yearly_fee_cents',
    Header: 'Yearly fee',
    accessor: 'yearly_fee_cents',
    Cell: ({value}) => (value ? formatPrice(value) : ''),
    text_styles: 'body2',
    sort: 'yearly_fee_cents',
  },
  {
    id: 'start_date',
    Header: 'Start date',
    accessor: 'start_date',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'start_date',
  },
  {
    id: 'accepted_at',
    Header: 'Accepted at',
    accessor: 'accepted_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'accepted_at',
  },
  {
    id: 'rejected_at',
    Header: 'Rejected at',
    accessor: 'rejected_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'rejected_at',
  },
  {
    id: 'rejected_by',
    Header: 'Rejected by',
    accessor: 'rejected_by',
    text_styles: 'body2',
    sort: 'rejected_by',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
