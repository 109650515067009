import React from 'react';
import {useTable} from 'react-table';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import styles from './Table.module.scss';
import TableHeaderButton from './table_header_button/TableHeaderButton.js';

export default function Table({
  data,
  onOpen,
  orderBy,
  sort_column,
  sort_direction,
  columns,
}) {
  const {getTableProps, headerGroups, getTableBodyProps, rows, prepareRow} =
    useTable({columns, data});

  return (
    <table className={styles.table} {...getTableProps()}>
      <thead className={styles.thead}>
        {headerGroups.map((headerGroup, index) => (
          <tr key={index} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th key={idx} className={styles.th} {...column.getHeaderProps()}>
                {column.sort ? (
                  <TableHeaderButton
                    orderBy={orderBy}
                    column={column}
                    sort_column={sort_column}
                    sort_direction={sort_direction}
                  />
                ) : (
                  <div className={styles.header}>{column.render('Header')}</div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} className={styles.tr} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  // getCellProps() has a key
                  <td
                    {...cell.getCellProps()}
                    className={onOpen && styles.pointer}
                    onClick={() => onOpen?.(row.original)}>
                    {typeof cell.value === 'boolean' ? (
                      BooleanValue(cell)
                    ) : (
                      <div
                        className={classNames(
                          text_styles[cell.column.text_styles],
                          styles.cell,
                        )}>
                        {cell.render('Cell')}
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function BooleanValue({value}) {
  return <div className={styles.boolean}>{value ? '✓' : '✗'}</div>;
}
