import React from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames/bind';
import TextInput from 'shared/components/TextInput.js';
import {BAD_REQUEST, UNAUTHORIZED} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import {login} from '../../actions.js';
import {handleError, alert} from '../../effects.js';

import Screen from './Screen.js';
import styles from './LoginScreen.module.scss';

export default function LoginScreen() {
  const {
    register,
    formState: {isSubmitting, isValid},
    handleSubmit,
  } = useForm({mode: 'onChange'});

  const onSubmit = handleSubmit(proceed);

  return (
    <Screen>
      <div className={styles.content}>
        <p className={text_styles.h1_bright_bold_centered}>
          Welcome to getmomo backoffice
        </p>
        <div className={styles.card}>
          <div className={styles.cardTop}>
            <p className={text_styles.body1_bold_centered}>
              Please enter your getmomo backoffice credentials.
            </p>
          </div>

          <form onSubmit={onSubmit}>
            <TextInput
              type="email"
              placeholder="E-Mail"
              className={classNames(styles.input, styles.input_email)}
              autoFocus={true}
              {...register('email_address', {required: true})}
            />

            <TextInput
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              className={styles.input}
              {...register('password', {required: true})}
            />
            <div className={styles.button_container}>
              <Button
                type="submit"
                title="Login"
                loading={isSubmitting}
                disabled={!isValid}
                className={styles.button}
              />
            </div>
          </form>
        </div>
      </div>
    </Screen>
  );
}

async function proceed({email_address, password}) {
  try {
    await login({email_address, password});
  } catch (err) {
    if (err.code === UNAUTHORIZED || err.code === BAD_REQUEST) {
      showCredentialError();
    } else {
      handleError(err);
    }
  }
}

function showCredentialError() {
  alert({
    text: 'Wrong E-Mail or Password',
    label: 'OK',
    removeBackdrop: true,
  });
}
