import React from 'react';

import styles from './Screen.module.scss';

export default function Screen({children}) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
