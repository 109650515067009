import React, {useState} from 'react';
import Input from 'shared/components/Input.js';
import {Controller, useForm} from 'react-hook-form';
import {setFormErrors} from 'shared/effects.js';
import Button from 'shared/components/Button.js';
import Checkbox from 'shared/components/checkbox/Checkbox.js';
import Dialog from 'shared/components/dialog/Dialog.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {alert, handleError, confirmClose} from '../../effects.js';
import {createBackofficeUser} from '../../actions.js';

import styles from './BackofficeUserDialog.module.scss';

export default function CreateBackofficeUserDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {notify} = useNotify();
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, setError, setVisible, notify}),
  );

  const Footer = (
    <div className={styles.footer}>
      <Button
        title="Save"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </div>
  );

  return (
    <Dialog
      title="Create Backoffice User"
      footer={Footer}
      onHide={() => {
        return confirmClose({isDirty});
      }}
      show={visible}
      {...props}>
      <div>
        <Input
          label="First name"
          {...register('first_name')}
          error={errors.first_name?.message}
        />
        <Input
          label="Last name"
          {...register('last_name')}
          error={errors.last_name?.message}
        />
        <Input
          label="Email address"
          {...register('email_address')}
          error={errors.email_address?.message}
        />
        <Input
          label="Password (min 12 characters)"
          type="password"
          autoComplete="new-password"
          {...register('password')}
          error={errors.password?.message}
        />
        <Controller
          control={control}
          name="is_admin"
          defaultValue={false}
          render={({field: {value, onChange, name}}) => (
            <Checkbox
              id={name}
              value={value}
              onChange={onChange}
              label="Admin"
            />
          )}
        />
      </div>
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, notify}) {
  try {
    await createBackofficeUser({...fields});
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'Backoffice user was created'});
  setVisible(false);
}
