import {useForm, Controller} from 'react-hook-form';
import Input from 'shared/components/Input.js';
import Checkbox from 'shared/components/checkbox/Checkbox.js';
import Button from 'shared/components/Button.js';
import {setFormErrors} from 'shared/effects.js';
import Dialog from 'shared/components/dialog/Dialog.js';
import {useState} from 'react';
import {useNotify} from 'shared/NotifyProvider.js';

import {alert, handleError, confirmClose} from '../../effects.js';
import {createTenantPromotionCampaign} from '../../actions.js';

export default function CreateTenantPromotionCampaignDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {notify} = useNotify();
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
  });
  const onSubmit = handleSubmit((fields) =>
    proceed({fields, setError, setVisible, notify}),
  );

  const Footer = (
    <Button
      title="Save"
      loading={isSubmitting}
      onClick={onSubmit}
      disabled={!isDirty}
    />
  );

  return (
    <Dialog
      show={visible}
      title="Create Tenant Promotion Campaign"
      footer={Footer}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <Input label="Name" {...register('name')} error={errors.name?.message} />
      <Input
        label="Header"
        {...register('header')}
        error={errors.header?.message}
      />
      <Input label="Body" {...register('body')} error={errors.body?.message} />
      <Controller
        control={control}
        name="is_default"
        render={({field: {value, onChange, name}}) => (
          <Checkbox
            id={name}
            value={value}
            onChange={onChange}
            label="Default tenant promotion campaign"
            error={errors[name]?.message}
          />
        )}
      />
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, notify}) {
  try {
    await createTenantPromotionCampaign({...fields});
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'Tenant promotion campaign was created.'});
  setVisible(false);
}
