import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function PropertyOwnerReferenceField({id, ...props}) {
  const history = useHistory();

  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listPropertyOwners"
      accessor="display_name"
      action={(property_owner) => {
        history.push(
          `${BACKOFFICE_PATHS.property_owners}/${property_owner.id}`,
        );
      }}
    />
  );
}
