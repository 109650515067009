import {useHistory} from 'react-router-dom';
import {formatIban} from 'shared/utils.js';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function DepositAccountVibanReferenceField({id, ...props}) {
  const history = useHistory();
  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listDepositAccountVibans"
      accessor={({viban}) => formatIban(viban)}
      action={(deposit_account_viban) => {
        history.push(
          `${BACKOFFICE_PATHS.deposit_account_vibans}/${deposit_account_viban.id}`,
        );
      }}
    />
  );
}
