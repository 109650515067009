export async function uploadToMinio(file, upload_url, signal) {
  const res = await fetch(upload_url, {
    method: 'PUT',
    signal,
    body: file,
  });

  if (!res.ok) {
    const error = new Error(`${res.status} ${res.statusText}`);
    throw error;
  }
}
