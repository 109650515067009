import {formatDate} from 'shared/utils.js';
import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';

import PropertyOwnerDialog from './PropertyOwnerDialog.js';
import styles from './PropertyOwnersScreen.module.scss';

export default function PropertyOwnersScreen() {
  const {
    orderBy,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listPropertyOwners'});

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.property_owners}/:id`}>
        <PropertyOwnerDialog
          onExited={() => history.push(BACKOFFICE_PATHS.property_owners)}
        />
      </Route>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(property_owner) =>
            history.push(
              `${BACKOFFICE_PATHS.property_owners}/${property_owner.id}`,
            )
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'name',
    Header: 'Name',
    accessor: (row) =>
      `${row.first_name ? `${row.first_name} ` : ''}${row.last_name ?? ''}`,
    text_styles: 'body2',
    sort: 'first_name',
  },
  {
    id: 'company_name',
    Header: 'Company name',
    accessor: 'company_name',
    text_styles: 'body2',
    sort: 'company_name',
  },
  {
    id: 'manager',
    Header: 'Manager',
    accessor: 'manager_id',
    Cell: ({value}) => <ManagerReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'manager_id',
  },
  {
    id: 'email_address',
    Header: 'Email',
    accessor: 'email_address',
    Cell: ({value}) => <a href={`mailto:${value}`}>{value}</a>,
    text_styles: 'body2',
    sort: 'email_address',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
