import {formatDate} from 'shared/utils.js';
import {useSelector} from 'react-redux';
import {Route, Switch, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import {useFetchTableData} from '../../hooks.js';

import BackofficeUserDialog from './BackofficeUserDialog.js';
import styles from './BackofficeUsersScreen.module.scss';
import CreateBackofficeUserDialog from './CreateBackofficeUserDialog.js';

export default function BackofficeUsersScreen() {
  const {refresh} = useSelector((state) => state.backoffice_users);

  const {
    orderBy,
    resetTable,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listBackofficeUsers', refresh});

  const history = useHistory();

  function routeToListScreen() {
    history.push(BACKOFFICE_PATHS.backoffice_users);
  }

  return (
    <Layout
      button={<ButtonCreateBackofficeUser {...{resetTable, history}} />}
      search={<SearchInput onValue={setSearchQuery} />}>
      <Switch>
        <Route path={`${BACKOFFICE_PATHS.backoffice_users}/new`}>
          <CreateBackofficeUserDialog onExited={routeToListScreen} />
        </Route>
        <Route path={`${BACKOFFICE_PATHS.backoffice_users}/:id`}>
          <BackofficeUserDialog onExited={routeToListScreen} />
        </Route>
      </Switch>
      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(backoffice_user) =>
            history.push(
              `${BACKOFFICE_PATHS.backoffice_users}/${backoffice_user.id}`,
            )
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

function ButtonCreateBackofficeUser({resetTable, history}) {
  return (
    <ButtonAdd
      title="Create Backoffice User"
      onClick={() => {
        resetTable();
        history.push(`${BACKOFFICE_PATHS.backoffice_users}/new`);
      }}
    />
  );
}

const columns = [
  {
    id: 'name',
    Header: 'Name',
    accessor: (row) => {
      return `${row.first_name} ${row.last_name}`;
    },
    text_styles: 'body2',
    sort: 'first_name',
  },
  {
    id: 'email_address',
    Header: 'Email',
    accessor: 'email_address',
    Cell: ({value}) => <a href={`mailto:${value}`}>{value}</a>,
    text_styles: 'body2',
    sort: 'email_address',
  },
  {
    id: 'is_admin',
    Header: 'Admin',
    accessor: 'is_admin',
    text_styles: 'body2',
    sort: 'is_admin',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
