import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function ManagerReferenceField({id, ...props}) {
  const history = useHistory();

  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listManagers"
      accessor="display_name"
      action={(manager) => {
        history.push(`${BACKOFFICE_PATHS.managers}/${manager.id}`);
      }}
    />
  );
}
